import React, { useEffect, useState } from 'react';
import { useAuthStore } from '../../../stores/useAuthStore.js';
import { Button, useMediaQuery } from '@mui/material';
import './FollowButton.css';
import { useFollowStore } from '../../../stores/useFollowerStore.js';
import { useQuery } from '../../../context/QueryContext.js';
import { useTheme } from '@emotion/react';

const FollowButton = ({ userId, initialIsFollowing = false, profileLoading }) => {
  const [isFollowing, setIsFollowing] = useState(initialIsFollowing);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuthStore();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { newPostCreated, setNewPostCreated } = useQuery();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    // Check initial follow status
    const checkFollowStatus = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/v1/followers/${userId}/status`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });
        const data = await response.json();
        setIsFollowing(data.isFollowing);
      } catch (error) {
        console.error('Error checking follow status:', error);
      } finally {
        setIsLoading(false);
      }
    };

    checkFollowStatus();
  }, [userId]);

  const handleFollowToggle = async () => {
    if (!user) return;
    setIsLoading(true);

    try {
      if (isFollowing) {
        await fetch(`${BASE_URL}/api/v1/followers/${userId}`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });
        useFollowStore.getState().decrementFollowerCount(userId);
        useFollowStore.getState().decrementFollowingCount(user); // logged-in user's following
        setNewPostCreated((prevState) => !prevState); // Toggle newPostCreated to refresh gallery state
      } else {
        await fetch(`${BASE_URL}/api/v1/followers`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({ followedId: userId }),
        });
        useFollowStore.getState().incrementFollowerCount(userId);
        useFollowStore.getState().incrementFollowingCount(user); // logged-in user's following
        setNewPostCreated((prevState) => !prevState); // Toggle newPostCreated to refresh gallery state
      }
      setIsFollowing(!isFollowing);
    } catch (error) {
      console.error('Follow toggle error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Don't show button if it's your own profile
  if (user === userId) return null;

  return profileLoading ? (
    <div className="spinner" />
  ) : (
    <Button
      onClick={handleFollowToggle}
      variant={isFollowing ? '' : 'contained'}
      disabled={isLoading || !user}
      sx={{
        fontSize: isMobile ? '1rem' : '1rem',
        padding: '3px 8px',
        height: isMobile ? '1.5rem' : '100%',
        border: isFollowing ? '1px solid green' : '',
      }}
    >
      {isLoading ? <div className="spinner" /> : isFollowing ? 'Following' : 'Follow'}
    </Button>
  );
};

export default FollowButton;
