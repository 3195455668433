const BASE_URL = process.env.REACT_APP_BASE_URL;

export async function getFollowerCount(userId) {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/followers/count/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    const data = await response.json();

    return data;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error in fetch-followers: ', e);
    throw e;
  }
}

export async function getFollowerList(userId) {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/followers/followers/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    const data = await response.json();

    return data;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error in fetch-followers: ', e);
    throw e;
  }
}

export async function getFollowingList(userId) {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/followers/following/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    const data = await response.json();

    return data;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error in fetch-followers: ', e);
    throw e;
  }
}

// Add this to fetch-followers.js
export async function getUniqueFollowersAndFollowing(userId) {
  try {
    const [followersData, followingData] = await Promise.all([
      getFollowerList(userId),
      getFollowingList(userId),
    ]);

    // Merge and filter duplicates based on 'sub'
    return Array.from(
      new Map(
        [...followersData.followers, ...followingData.following].map((user) => [user.sub, user])
      ).values()
    );
  } catch (e) {
    console.error('Error getting unique followers and following:', e);
    throw e;
  }
}
