import { Box, Button } from '@mui/material';
import React from 'react';

export default function SubscriptionButtons({ handleClickSubscribe }) {
  return (
    <Box
      className={'subscription-button-wrapper'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: (theme) => theme.spacing(2),
      }}
    >
      <Button
        variant="contained"
        color="primary"
        value="price_1QteUoLJ67I09H9nQ9ctpOx0" //! LIVE monthly
        onClick={handleClickSubscribe}
      >
        Choose Monthly Subscription $15/mth
      </Button>
      <Button
        variant="contained"
        color="primary"
        value="price_1QteV4LJ67I09H9nw9NtWSvO" //! LIVE yearly
        onClick={handleClickSubscribe}
      >
        Choose Yearly Subscription $150/yr
      </Button>
    </Box>
  );
}
